import React from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'

import { screenSizes } from '../theme'

import Layout from '../components/layout'
import SEO from '../components/seo'
import RollingText from '../components/RollingText'
import RollingTextListBase from '../components/RollingTextList'

import BigLink from '../components/BigLink'

import ThreeModelView from '../components/ThreeModelView'
import WeatherWidget from '../components/WeatherWidget'

const cities = [
  {
    name: 'Cape Town',
    address: 'Upper Pepper Workspace, 14 Pepper St, Cape Town, 8001',
  },
  {
    name: 'Pretoria',
    address: 'Colab, Park Lane West Building, Pretoria, 0181',
  },
]

const SwiperContainer = styled.div`
  display: grid;
  grid-template: 170px auto 1fr auto / 20px auto;
  width: 100%;
  height: 100vh;
  min-height: 750px;

  .swiper-container {
    width: 100%;
    grid-area: 1 / 1 / 5 / 3;
  }
  .swiper-wrapper {
    width: 100%;
  }
  .swiper-slide {
    width: 101%;
    &:first-child {
      width: 100vw;
    }

    &:nth-child(2) {
      width: 100vw;
    }
  }
  @media (min-width: ${screenSizes.desktop}px) {
    grid-template: 170px auto 1fr auto / 340px auto;

    .swiper-container {
      grid-area: 1 / 1 / 5 / 3;
    }
  }
  @media (min-width: ${screenSizes.tablet}px) {
    .swiper-slide {
      &:first-child {
        width: 100vw;
      }

      &:nth-child(2) {
        width: 100vw;
      }
    }
  }
`

const ThreeModelContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
`

const TopContainer = styled.div`
  z-index: 2;
  grid-area: 2 / 2 / 2 / 2;
  pointer-events: all;
  span {
    font-size: 30pt;
    color: white;
  }
  p {
    margin-top: 34px;
    text-transform: uppercase;
    color: white;
  }

  .text {
    margin-top: 15px;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    .text {
      margin-top: 34px;
    }
    span {
      font-size: 40pt;
    }
  }
`

const BottomContainer = styled.div`
  pointer-events: none;
  z-index: 2;
  grid-area: 4 / 2 / 4 / 2;
  padding-bottom: 60px;

  span {
    color: white;
  }
  @media (min-width: ${screenSizes.tablet}px) {
    padding-bottom: 0px;
  }
`

const ContactSeperator = styled.span`
  display: none;
  @media (min-width: ${screenSizes.tablet}px) {
    display: inline-block;
  }
`

const BiggishLink = styled(BigLink)`
  margin-top: -3px;

  h3 {
    font-size: 20pt;
    margin-top: 20px;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    display: inline-block;
    height: auto;
    h3 {
      font-size: 40pt;
      margin-top: 0px;
      margin-bottom: 8px;
    }
  }
`

const CityName = styled(RollingText)`
  font-size: 6.4vw;
`

const CityAddress = styled(RollingText)`
  font-size: 3vw;
  margin-bottom: 40px;
  display: block;
  @media (min-width: ${screenSizes.tablet}px) {
    font-size: 15px;
  }
`

const RollingTextList = styled(RollingTextListBase)`
  font-size: 10pt !important;
`

const RollingTextContainer = styled.p`
  display: none;

  @media (min-width: ${screenSizes.tablet}px) {
    display: block;
  }
`

const SlideNumberContainer = styled.div`
  display: flex;
  p,
  span {
    line-height: 7pt;
    font-size: 7pt;
    margin-bottom: 15px;
    color: white;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    display: none;
  }
`

class ContactUsContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      weather: null,
      animate: false,
      activeSlide: 0,
    }

    this.timeout = null
    this.onSliderMove = this.onSliderMove.bind(this)
    this.onSlideChangeEnd = this.onSlideChangeEnd.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
  }

  async componentDidMount() {
    const weather = await fetch('/weather.json').then(result => result.json())
    this.setState({ weather: weather.list })
  }

  onSliderMove(event) {
    window.clearTimeout(this.timeout)
    if (event && (event.movementX || event.type === 'touchmove')) {
      this.setState({ animate: true })
    }
  }

  onSlideChangeEnd() {
    window.clearTimeout(this.timeout)
    if (this.swiper) {
      let activeSlide = this.swiper.activeIndex
      this.setState({ activeSlide }, () => {
        this.timeout = window.setTimeout(() => {
          this.setState({ animate: false })
        }, 300)
      })
    }
  }

  onTouchStart() {
    window.clearTimeout(this.timeout)
    this.setState({ active: true })
  }

  onTouchEnd() {
    window.clearTimeout(this.timeout)
    this.setState({ active: false }, () => {
      this.timeout = window.setTimeout(() => {
        this.setState({ animate: false })
      }, 300)
    })
  }

  render() {
    const { animate, activeSlide, active } = this.state

    const swiperParams = {
      touchStartPreventDefault: false,
      slidesPerView: 'auto',
      freeMode: false,
      speed: 200,
      touchRatio: 1,
      breakpointsInverse: true,
      breakpoints: {
        [screenSizes.desktop]: {
          touchRatio: 2,
        },
      },
      on: {
        slideChangeTransitionStart: this.onSliderMove,
        slideChangeTransitionEnd: this.onSlideChangeEnd,
        sliderMove: this.onSliderMove,
        transitionEnd: this.onSlideChangeEnd,
        touchStart: this.onTouchStart,
        touchEnd: this.onTouchEnd,
      },
    }

    return (
      <div>
        <SEO title="Contact Us" keywords={[`Teamgeek`]} />
        <SwiperContainer active={active}>
          <Swiper
            {...swiperParams}
            ref={node => {
              if (node) this.swiper = node.swiper
            }}
          >
            <div>
              <ThreeModelContainer>
                <ThreeModelView
                  src="/models/table-mountain.glb"
                  cameraPosResponsive={{
                    [screenSizes.desktop]: {
                      x: 0.3,
                      y: 2.5,
                      z: 8.5,
                    },
                  }}
                  cameraPos={{
                    x: 0.3,
                    y: 2.5,
                    z: 8.5,
                  }}
                  rotationOffset={{
                    y: -0.1,
                    x: -0.2,
                  }}
                  rotationSpeed={{
                    x: 0.08,
                    y: 0.2,
                  }}
                  renderSize={{
                    width: 1152,
                    height: 781,
                  }}
                />
              </ThreeModelContainer>
            </div>
            <div>
              <ThreeModelContainer>
                <ThreeModelView
                  src="/models/telkom-tower.glb"
                  cameraPos={{
                    x: -10,
                    y: 2.5,
                    z: 16,
                  }}
                  rotationOffset={{
                    y: -0.25,
                    x: -0.1,
                  }}
                  rotationSpeed={{
                    x: 0.1,
                    y: 0.25,
                  }}
                  renderSize={{
                    width: 2400,
                    height: 1000,
                  }}
                  meshIndex={0}
                />
              </ThreeModelContainer>
            </div>
          </Swiper>
          <TopContainer>
            <RollingTextContainer
              style={{ fontSize: '10pt', fontWeight: 'bold' }}
            >
              /&nbsp;&nbsp;
              <RollingTextList
                textList={[
                  'Hello',
                  'Hi daar',
                  'Aweh',
                  '你好',
                  'Sawubona',
                  'Dumela',
                  'Здравейте',
                  'Olá',
                ]}
              />
            </RollingTextContainer>
            <div>
              <BiggishLink href="mailto:hello@teamgeek.io">Email</BiggishLink>
              <ContactSeperator style={{ marginRight: 15 }}>
                &nbsp;/
              </ContactSeperator>
              <BiggishLink
                href="https://wa.me/27648912008"
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp
              </BiggishLink>
              <ContactSeperator style={{ marginRight: 15 }}>
                &nbsp;/
              </ContactSeperator>
              <BiggishLink href="tel:+27648912008" rel="noopener noreferrer">
                Call*
              </BiggishLink>
            </div>
            <p className="text" style={{ fontSize: '8pt' }}>
              *only if you really <strong>really</strong> have to
            </p>
          </TopContainer>
          <BottomContainer>
            <SlideNumberContainer>
              <p>
                <RollingText
                  numbersOnly
                  animate={animate}
                  text={(activeSlide + 1).toString()}
                />{' '}
                &mdash; 2
              </p>
            </SlideNumberContainer>
            <h1>
              <CityName animate={animate} text={cities[activeSlide].name} />
              <WeatherWidget
                weather={this.state.weather}
                city={animate ? null : cities[activeSlide].name}
              />
            </h1>
            <CityAddress animate={animate} text={cities[activeSlide].address} />
          </BottomContainer>
        </SwiperContainer>
      </div>
    )
  }
}

class ContactUsPage extends React.Component {
  render() {
    return (
      <Layout headerTitle="" hideFooter>
        <ContactUsContent />
      </Layout>
    )
  }
}

export default ContactUsPage
