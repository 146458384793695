import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Text = styled.span``

class RollingText extends PureComponent {
  static propTypes = {
    textList: PropTypes.array.isRequired,
    letterDelay: PropTypes.number.isRequired,
    wordDelay: PropTypes.number.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    letterDelay: 100,
    wordDelay: 2500,
  }

  constructor(props) {
    super(props)

    this.state = {
      textList: props.textList,
      activeIndex: 0,
      currentText: props.textList[0],
    }

    this.timeout = null
    this.animateCount = 0
    this.animateText = this.animateText.bind(this)
    this.changeText = this.changeText.bind(this)
    this.stop = this.stop.bind(this)
    this.start = this.start.bind(this)
  }

  componentDidMount() {
    this.timeout = window.setTimeout(() => {
      this.animateText()
    }, 1000)
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout)
  }

  stop() {
    window.clearTimeout(this.timeout)
  }

  start() {
    this.animateText()
  }

  animateText() {
    const { wordDelay, letterDelay } = this.props
    const { currentText, activeIndex, textList } = this.state
    const targetText = textList[activeIndex]
    const chars = 'abcdefghijklmnopqrstuvxyz'

    if (this.animateCount < 10) {
      let newText = ''

      for (var i = 0; i < currentText.length; i++) {
        const randomChar = chars.charAt(
          Math.floor(Math.random() * chars.length)
        )
        newText += randomChar
      }

      if (newText.length > targetText.length) {
        newText = newText.substring(0, newText.length - 2)
      } else if (newText.length < targetText.length) {
        const randomChar = chars.charAt(
          Math.floor(Math.random() * chars.length)
        )
        newText += randomChar
      }

      this.setState({ currentText: newText }, () => {
        this.timeout = window.setTimeout(() => {
          this.animateCount++
          this.animateText()
        }, letterDelay)
      })
    } else {
      this.setState(
        {
          currentText: targetText,
        },
        () => {
          this.timeout = window.setTimeout(() => {
            this.animateCount = 0
            this.changeText()
          }, wordDelay)
        }
      )
    }
  }

  changeText() {
    const { activeIndex, textList } = this.state

    let newIndex = activeIndex + 1

    if (newIndex + 1 > textList.length) {
      newIndex = 0
    }

    this.setState(
      {
        activeIndex: newIndex,
      },
      () => {
        this.animateText()
      }
    )
  }

  render() {
    const { className } = this.props
    const { currentText } = this.state
    return <Text className={className}>{currentText}</Text>
  }
}

export default RollingText
